*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}
body{
    font-size: 14px;
    background-color: #EFEFEF;
    color: #727272;
}

container--main{
    position: relative;
}

h1, h2, h3{
    margin: 0.5em;
    font-weight: 200;
    text-align: center;
}

strong{
    font-weight: 300;
    color: #3a3a3a;
}

a{
    color: inherit;
    text-decoration: none;
}

a:visited{
    color: inherit;
    text-decoration: none;
}

.page{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    height: 100%;
    width: 100%;
}

@media screen and (min-width: 900px) {
    .page{
        padding-left: 200px;
    }
    
}
