.container--nav{
    position: absolute;
    width: 100%;
}

.desktop-nav--container{
    display: none;
}
.mobile-nav--container{
    z-index: 100;
    position: fixed;
    width: 100%;
}

.mobile-nav{
    z-index: 10;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    height: 50px;
    width: 100%;
    background-color: #EFEFEF;
    border: none;
    border-bottom: 1px solid #ccc;
}

.mobile-nav > button{
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.mobile-nav > button > .la-bars{
    font-size: 2em;
    color: #939393;
}

.mobile--nav__links{
    z-index: 5;
    position: absolute;
    top: -400px;
    left: 0;
    width: 100%;
    padding: 1em;
    background-color: #EFEFEF;
    border-bottom: 1px solid #ccc;
    transition: top 450ms linear;
}

.active{
    top: 30px;
}

.nav-links__ul{
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    list-style: none;
}

.nav-links__li{
    margin: 1.5em 0;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.nav-links__link{
    text-decoration: none;
    transition: letter-spacing 200ms ease;
}

.nav-links__link:hover{
    cursor: pointer;
    color: #3a3a3a;
    letter-spacing: 4px;
}

.nav-links__link:visited{
    color: #3a3a3a;
}

.nav__name{
    color: #3a3a3a;
    text-transform: uppercase;
    letter-spacing: 3px;
} 

@media screen and (min-width: 900px) {
    .mobile-nav--container{
        display: none;
    }
    .desktop-nav--container{
        z-index: 100;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1em 2em;
        height: 100vh;
        width: 200px;
        border-right: 1px solid #ccc;
        background-color: #EFEFEF;
    }
    .desktop-nav--container > .nav__name{
        margin-bottom: 1em;
    }
    .desktop--nav_links{
        margin: 0;
        justify-content: flex-start;
    }

    .desktop--nav_links > .nav-links__li{
        margin: 1em 0;
    }

}