.container--projects{
    justify-content: flex-start;
}
.container--project{
    position: relative;
    min-width: 100%;
    margin: 2em 0;
    border: 1px solid #d4d4d4;
    color: #fff
}
.container--project:first-of-type{
    margin-top: 6em;
}

.project__img{
    width: 100%;
    height: 100%;
}
.project__info{
    opacity: 0;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(63, 63, 63, 0.795);
    backdrop-filter: blur(5px);
    transition: opacity 200ms ease-in-out;
    cursor: pointer;
}

.project__info::after{
    content: '';
    position: absolute;
    margin: 1em;
    height: 90%;
    width: 95%;
    border: 1px solid #878787;
}

.project__name > strong{
    color: #fff;
}

.project__link-container{
    display: flex;
}

.project__link_container__link{
    z-index: 10;
    padding: 0.5em 1em;
    margin: 0.5em;
    border: 1px solid #878787;
    cursor: pointer;
    color: #ffffff;
    transition: all 200ms linear;
}

.project__link_container__link:hover{
    color: #fff;
    border: 1px solid #fff;
}


@media screen and (min-width: 900px) {
    .container--projects{
        flex-direction: row;
    }

    .container--project:first-of-type{
        margin: 0 3em;
    }

    .container--project{
        margin: 0 2em;
        min-width: 80%;
    }
}